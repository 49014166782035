function Icon({
  type,
  className = '',
  disabled,
  color,
  style,
  rotate,
  ...props
}) {
  return (
    <>
      <span
        className={
          'icon icon-' + type + ' ' + className + (rotate ? ' rotate' : '')
        }
        disabled={disabled}
        style={{ color: color, ...style }}
        {...props}
      ></span>
      <style jsx>{`
        .rotate {
          // TODO Rotation
        }
        [disabled] {
          opacity: 0.4;
          pointer-events: none;
          cursor: not-allowed;
        }
        .icon {
          font-size: 20px;
          vertical-align: text-bottom;
        }
        .selected {
          color: var(--primary);
        }
        .selectable {
          cursor: pointer;
        }
      `}</style>
    </>
  );
}

export default Icon;
