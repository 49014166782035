import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import reducers from '../reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state) => {
    // Set tag if the user is using imperial units.
    if (state.user) {
      scope.setTag('userId', state.user._id);
      scope.setTag('email', state.user.email);
    }
  },
});

const persistConfig = {
  key: 'root',
  whitelist: [
    'cartProducts',
    'cartProductsVetrina',
    'previousRoute',
    'referralCode',
    'anonymousUserId',
  ],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

let composeEnhancers = compose;
if (typeof window !== 'undefined') {
  composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 100,
      })) ||
    compose;
}

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer)
);
export const persistor = persistStore(store);

export const getState = store.getState;
export const dispatch = store.dispatch;
