export function channels(state = { products: [] }, action) {
  switch (action.type) {
    case 'SET_CHANNEL_PRODUCTS':
      state.products = action.payload || [];
      return state;
    case 'SET_CHANNEL':
      state.products = action.payload || [];
      return action.payload;
    default:
      return state;
  }
}
