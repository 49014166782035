export function addresses(state = [], action) {
  switch (action.type) {
    case 'ADDRESSES':
      return action.payload;

    default:
      return state;
  }
}

export function searchedAddressesByEmail(state = [], action) {
  switch (action.type) {
    case 'SEARCHED_ADDRESSES_BY_EMAIL':
      return action.payload;

    default:
      return state;
  }
}
