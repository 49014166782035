import { BASIC_URL } from 'tools/constants';
import { getToken, getQueryString } from 'tools/utils';
import { ShopifyTokenProvider } from './shopifyTokenProvider';
import { dispatch } from 'store/index';
import { setIsLoading } from 'actions/loader';

function request(
  url,
  {
    method = 'GET',
    data,
    params,
    headers,
    responseType = 'json',
    useBaseURL = true,
    useLoader = true,
    ...config
  } = {}
) {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  let shopifyTokenProvider = ShopifyTokenProvider.getInstance();
  let shopifyToken = shopifyTokenProvider.token;
  let token = getToken();

  if (!token && shopifyToken) {
    token = shopifyToken;
  }

  if (token) {
    defaultHeaders['Authorization'] = `Bearer ${token}`;
  }

  return new Promise((resolve, reject) => {
    if (useLoader) {
      dispatch(setIsLoading({ isLoading: true, loaderType: 'raised' }));
    }
    url = useBaseURL
      ? `${BASIC_URL}${url}${getQueryString(params)}`
      : `${url}${getQueryString(params)}`;
    fetch(url, {
      method,
      headers: {
        ...defaultHeaders,
        ...headers,
      },
      body: typeof data === 'string' ? data : JSON.stringify(data),
    })
      .then((response) => {
        if (responseType === 'json') {
          response
            .json()
            .then((data) => {
              response.data = data;
              if (response.status >= 400) {
                reject(response);
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              if (
                error.message === 'Unexpected token < in JSON at position 0'
              ) {
                resolve({ data: 'Data not JSON' });
              } else {
                reject(error);
              }
            });
        } else if (responseType === 'blob') {
          response
            .blob()
            .then((data) => {
              response.data = data;
              if (response.status >= 400) {
                reject(response);
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              if (
                error.message === 'Unexpected token < in JSON at position 0'
              ) {
                resolve({ data: 'Data not JSON' });
              } else {
                reject(error);
              }
            });
        }
      })
      .catch((error) => reject(error))
      .finally(() => {
        if (useLoader) {
          setTimeout(() => {
            dispatch(setIsLoading({ isLoading: false, loaderType: 'raised' }));
          }, 100);
        }
      });
  });
}

export default request;
