import { PERMISSIONS } from 'tools/constants';

function permissions(payload) {
  return { type: 'SET_PERMISSIONS', payload };
}

const mapRolePermissions = (role) => {
  switch (role) {
    case 'admin':
      return Object.keys(PERMISSIONS).filter(
        (permission) =>
          permission !== PERMISSIONS.IS_FREE_USER &&
          permission !== PERMISSIONS.IS_ONLY_SOCIAL &&
          permission !== PERMISSIONS.IS_PRIVATE
      );
    case 'dropeasy':
      return [
        PERMISSIONS.CAN_CREATE_TURNKEY,
        PERMISSIONS.CAN_HAVE_2_LIST,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'dropeasysite':
      return [
        PERMISSIONS.CAN_CREATE_TURNKEY,
        PERMISSIONS.CAN_HAVE_2_LIST,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'dropeasyenterprise':
      return [
        PERMISSIONS.CAN_CREATE_TURNKEY,
        PERMISSIONS.CAN_HAVE_3_LISTS,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'dropeasyplus':
      return [
        PERMISSIONS.CAN_CREATE_TURNKEY,
        PERMISSIONS.CAN_HAVE_1_LIST,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'dropfile':
      return [
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'dropplus':
      return [
        PERMISSIONS.CAN_HAVE_3_LISTS,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];

    case 'droppro':
      return [
        PERMISSIONS.CAN_HAVE_1_LIST,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'droppybasic':
      return [
        PERMISSIONS.CAN_HAVE_1_LIST,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'droppystarter':
      return [
        PERMISSIONS.CAN_HAVE_1_LIST,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'droppylight':
      return [
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
        PERMISSIONS.CAN_HAVE_1_LIST,
        PERMISSIONS.CAN_UPGRADE_PLAN,
      ];
    case 'droppyplus':
      return [
        PERMISSIONS.CAN_HAVE_3_LISTS,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'shopify':
      return [
        PERMISSIONS.CAN_HAVE_1_LIST,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.CAN_DOWNLOAD_IMPORT_LIST_AS_FILE,
        PERMISSIONS.CAN_ADD_TO_CART,
      ];
    case 'free':
      return [
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_HAVE_1_LIST,
        PERMISSIONS.IS_FREE_USER,
        PERMISSIONS.CAN_ADD_TO_CART,
        PERMISSIONS.IS_PRIVATE,
      ];
    case 'bsocial':
      return [
        PERMISSIONS.CAN_HAVE_1_LIST,
        PERMISSIONS.CAN_UPGRADE_PLAN,
        PERMISSIONS.CAN_CONNECT_LIST,
        PERMISSIONS.IS_ONLY_SOCIAL,
        PERMISSIONS.CAN_ADD_TO_CART,
        PERMISSIONS.IS_PRIVATE,
      ];
    default:
      return [PERMISSIONS.CAN_UPGRADE_PLAN];
  }
};

export function setPermissions(roles = []) {
  return (dispatch) => {
    const userPermissions = {};
    const activeRoles = roles
      .filter(
        (role) => new Date(role.expires) >= new Date() || role?.name === 'free'
      )
      .map((role) => role.name);

    const mappedRolesPermissions = activeRoles.map((activeRole) =>
      mapRolePermissions(activeRole)
    );

    mappedRolesPermissions.forEach((mappedRolePermission) =>
      mappedRolePermission.forEach((permission) => {
        userPermissions[permission] = true;
      })
    );

    dispatch(permissions(userPermissions));
  };
}
