import { mergeDeep } from '../../tools/utils';

const initialState = {
  show_confirmation_email_message: false,
  confirmation_email: null,
  isActiveList: false,
  source: null,
  add_to_cart: false,
  close_popup: false,
  redirectUrl: '',
  channelcode: '',
  popupNewsLetterShown: false,
};

export function UI(state = initialState, action) {
  switch (action.type) {
    case 'IS_CONFIRMED':
    case 'SET_IS_ACTIVE_LIST':
    case 'SET_SOURCE':
    case 'SET_PUBLIC_TOKEN':
    case 'SET_WIX_TOKEN':
    case 'ADD_TO_CART':
    case 'IS_CLOSED':
    case 'SET_REDIRECT_URL':
    case 'SET_CHANNELCODE':
    case 'POPUP_NEWSLETTER_SHOWN':
      return mergeDeep({ ...state }, { ...action.payload });
    default:
      return state;
  }
}
export function cartProducts(state = [], action) {
  switch (action.type) {
    case 'CART_PRODUCTS':
      return action.payload;

    default:
      return state;
  }
}
export function cartProductsVetrina(state = [], action) {
  switch (action.type) {
    case 'CART_PRODUCTS_VETRINA':
      return action.payload;

    default:
      return state;
  }
}
export function howToStart(state = true, action) {
  switch (action.type) {
    case 'IS_ENABLED':
      return action.payload;

    default:
      return state;
  }
}

export function handleSidebar(state = false, action) {
  switch (action.type) {
    case 'IS_MINIMIZED':
      return action.payload;

    default:
      return state;
  }
}

export function previousRoute(state = [], action) {
  switch (action.type) {
    case 'SET_PREVIOUS_ROUTE':
      return action.payload;
    default:
      return state;
  }
}

export function referralCode(state = false, action) {
  switch (action.type) {
    case 'SET_REFERRALCODE':
      return action.payload;

    default:
      return state;
  }
}

export function anonymousUserId(state = '', action) {
  switch (action.type) {
    case 'SET_ANONYMOUS_USER_ID':
      return action.payload;
    default:
      return state;
  }
}
