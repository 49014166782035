export function getLocalStorage(item) {
  if (navigator.cookieEnabled) {
    return localStorage.getItem(item);
  }
}

export function setLocalStorage(item, value) {
  if (navigator.cookieEnabled) {
    localStorage.setItem(item, value);
  }
}
