import Icon from 'components/utils/Icon';
import { useEffect, useState } from 'react';

const icons = {
  success: 'check-circle',
  info: 'info',
  warning: 'alert-triangle',
  error: 'x-circle',
  loading: 'check-circle',
};

const DURATION = 6;

const Message = ({
  content,
  type = 'success',
  duration = DURATION,
  onClose = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const close = () => setIsOpen(false);

  useEffect(() => {
    const timeoutId = setTimeout(close, duration * 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [duration]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <div className={isOpen ? 'active message' : 'message'}>
      <div className={isOpen ? 'content active-content' : 'content'}>
        <span className="icon-x close" onClick={handleClose}></span>
        <div className={type + ' message-icon'}>
          <Icon type={icons[type]} />
        </div>
        <div className="content-message">
          <p>{content}</p>
        </div>
      </div>
      <style jsx>
        {`
          .message {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: var(--foreground);
            font-size: 15px;
            line-height: 1.5715;
            list-style: none;
            position: fixed;
            top: -140px;
            left: 0;
            z-index: 1010;
            width: 100%;
            pointer-events: none;
            transition: 0.3s ease-in;
            z-index:100000000000000000000;
          }
          .message.active {
            top: 8px;
          }
          .content {
            display: flex;
            position: relative;
            width: 500px;
            max-width: 100%;
            margin: auto;
            background: var(--background-utils);
            pointer-events: all;
            border-radius: var(--radius-md);
          }
          .active-content {
            box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
              0 9px 28px 8px #0000000d;
          }
          @media screen and (max-width: 768px) {
            .content {
              width: 325px;
              max-width: 100%;
            }
          }
          .close {
            position: absolute;
            color: var(--grey-light);
            right: 8px;
            top: 8px;
            cursor: pointer;
          }
          .content-message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: var(--margin-md);
          }
          .content-message p {
            margin: 0;
            line-height: 1.2rem;
          }
          .message-icon {
            display: flex;
            font-size: 22px;
            font-weight: 600;
            line-height: 45px;
            border-radius: 5px 0px 0px 5px;
            color: white;
            width: 40px;
            align-items: center;
            justify-content: center;
          }
          .message-icon span {
            position: absolute;
            top: 4px;
            left: 8px;
          }
          .success {
            background: var(--success);
          }
          .error {
            background: var(--error);
          }
          .info {
            background: var(--info);
          }
          .warning {
            background: var(--warning);
          }
        `}
      </style>
    </div>
  );
};

export default Message;
