import { mergeDeep } from 'tools/utils';
import { PERMISSIONS } from 'tools/constants';

// ALL PERMISSIONS set to false as default
const initialState = Object.keys(PERMISSIONS).reduce(
  (a, v) => ({ ...a, [v]: false }),
  {}
);
export function permissions(state = initialState, action) {
  switch (action.type) {
    case 'SET_PERMISSIONS':
      return mergeDeep({ ...initialState }, { ...action.payload });
    default:
      return state;
  }
}
