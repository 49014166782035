import ReactDOM from 'react-dom';
import Message from './MessageComponent';
import { isServer } from 'tools/utils';

if (!isServer()) {
  var element = document.createElement('div');
  document.body.appendChild(element);
}

function createElement() {
  const el = document.createElement('div');
  element.appendChild(el);

  return el;
}

export function open({ content, type, duration, onClose }) {
  ReactDOM.render(
    <Message
      content={content}
      type={type}
      duration={duration}
      onClose={onClose}
    />,
    createElement()
  );
}

export function success(content, config) {
  open({ content, type: 'success', ...config });
}

export function info(content, config) {
  open({ content, type: 'info', ...config });
}

export function warning(content, config) {
  open({ content, type: 'warning', ...config });
}

export function error(content, config) {
  open({ content, type: 'error', ...config });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  open,
  success,
  info,
  warning,
  error,
};
