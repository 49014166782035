// export const BASIC_URL = 'https://dev.bdroppy.com'
export const BASIC_URL = process.env.REPROXY_URL || 'https://dev.bdroppy.com';

export const TOKEN_LIFE = 7 * 24 * 60 * 60; // 7 days
export const ADMIN_TOKEN_LIFE = 7 * 24 * 60 * 60; // 7 days
export const TOKEN_NAME = 'token';
export const ADMIN_TOKEN_NAME = 'admin_token';

export const LANGUAGES = {
  en_US: { title: 'English', src: '/images/flags/en_US.svg' },
  it_IT: { title: 'Italiano', src: '/images/flags/it_IT.svg' },
  fr_FR: { title: 'Français', src: '/images/flags/fr_FR.svg' },
  es_ES: { title: 'Español', src: '/images/flags/es_ES.svg' },
};

export const SELECT_LOCALES = {
  it_IT: { title: 'Italiano' },
  en_US: { title: 'English' },
  fr_FR: { title: 'Français' },
  de_DE: { title: 'Deutsch' },
  el_GR: { title: 'Ελληνικά' },
  es_ES: { title: 'Español' },
  pl_PL: { title: 'Polski' },
  pt_PT: { title: 'Português' },
  ro_RO: { title: 'Română' },
  nl_NL: { title: 'Nederlands' },
  cs_CZ: { title: 'Čeština' },
  et_EE: { title: 'Eesti' },
  hu_HU: { title: 'Magyar' },
  sv_SE: { title: 'Svenska' },
  sk_SK: { title: 'Slovenčina' },
  ru_RU: { title: 'Русский' },
  lt_LT: { title: 'Lietuvių' },
  fi_FI: { title: 'Suomi' },
  bg_BG: { title: 'Български' },
  da_DK: { title: 'Dansk' },
};

export const LOCALES = [
  'it_IT',
  'en_US',
  'fr_FR',
  'de_DE',
  'el_GR', // el_EL
  'es_ES',
  'pl_PL',
  'pt_PT',
  'ro_RO',
  'nl_NL',
  'cs_CZ', // cs_CZ
  'et_EE', // manca
  'hu_HU',
  'sv_SE', // manca
  'sk_SK',
  'ru_RU', // manca
  'lt_LT',
  'fi_FI',
  'bg_BG',
  'da_DK', // dk_DK
];

// export const ORDER_STATUSES = [
//   { label: 'FAILED', value: 2000 },
//   { label: 'NOAVAILABILITY', value: 2001 },
//   { label: 'UNKNOWN', value: 2002 },
//   { label: 'API_ERROR', value: 2003 },
//   { label: 'BOOKED', value: 5 },
//   { label: 'CONFIRMED', value: 2 },
//   { label: 'WORKING_ON', value: 3001 },
//   { label: 'READY', value: 3002 },
//   { label: 'DISPATCHED', value: 3 },
//   { label: 'PENDING', value: 0 }
// ]

export const ORDER_STATUSES = {
  0: {
    label: 'Pending',
    alternativeLabel: 'Pending approval',
    className: 'pending',
    alternativeClassName: 'pending-approval',
  },
  1: { label: 'Payment waiting', className: 'waiting' },
  2: { label: 'Confirmed', className: 'confirmed' },
  3: { label: 'Dispatched', className: 'dispatched' },
  5: { label: 'Booked', className: 'booked' },
  2000: { label: 'Failed', alternativeLabel: 'Canceled', className: 'failed' },
  2001: { label: 'No available', className: '' },
  2002: { label: 'Unknown', className: '' },
  2003: { label: 'API error', className: '' },
  3001: { label: 'Working on', className: 'working-on' },
  3002: { label: 'Ready', className: 'ready' },
};
export const ORDER_RETURN_STATUSES = {
  0: { label: 'Request sent, waiting for approval', className: 'pending' },
  1: {
    label: 'Request authorized, shipping changed to customer',
    className: 'authorized_c',
  },
  2: {
    label: 'Request authorized, shipping payed by supplier',
    className: 'authorized',
  },
  3: { label: 'Return received in good condition', className: 'received_ok' },
  4: { label: 'Return received in poor condition', className: 'received_nok' },
  5: { label: 'Return not received', className: 'not_received' },
  7: { label: 'Return not authorized', className: 'refused' },
  9: { label: 'Return processing completed', className: 'completed' },
};

export const applyTo = {
  canale: 'canale',
  category: 'Category',
  subcategory: 'Sub category',
  brand: 'Brand',
  gender: 'Gender',
  season: 'Season',
  bestTaxable: 'Best taxable',
  streetPrice: 'Street price',
  suggestedPrice: 'SuggestedPrice',
};
export const genders = ['boy', 'girl', 'women', 'unisex', 'men'];
export const seasons = ['fw', 'ss', 'all-year'];
export const bestTaxables = ['=', '<>', '>', '>=', '<', '<='];

export const LIGHT_THEME_NAME = 'light';
export const DARK_THEME_NAME = 'dark';

export const PERMISSIONS = {
  IS_ADMIN: 'IS_ADMIN',
  CAN_CREATE_TURNKEY: 'CAN_CREATE_TURNKEY',
  CAN_HAVE_3_LISTS: 'CAN_HAVE_3_LISTS',
  CAN_HAVE_2_LIST: 'CAN_HAVE_2_LIST',
  CAN_HAVE_1_LIST: 'CAN_HAVE_1_LIST',
  CAN_UPGRADE_PLAN: 'CAN_UPGRADE_PLAN',
  CAN_CONNECT_LIST: 'CAN_CONNECT_LIST',
  CAN_DOWNLOAD_IMPORT_LIST_AS_FILE: 'CAN_DOWNLOAD_IMPORT_LIST_AS_FILE',
  IS_FREE_USER: 'IS_FREE_USER',
  IS_PRIVATE: 'IS_PRIVATE',
  CAN_ADD_TO_CART: 'CAN_ADD_TO_CART',
  IS_ONLY_SOCIAL: 'IS_ONLY_SOCIAL',
};

export const ADDRESS_TYPE = {
  BILLING: 'billing',
  SHIPPING: 'shipping',
};
export const AMAZON_MARKET_COUNTRY = [
  {
    value: '62d16215988f9063e08babab',
    label: 'Amazon IT',
  },
  {
    value: '62d16215988f9063e08babac',
    label: 'Amazon UK',
  },
  {
    value: '62d16215988f9063e08babaf',
    label: 'Amazon CA',
  },
  {
    value: '62d16215988f9063e08babb0',
    label: 'Amazon FR',
  },
  {
    value: '62d16215988f9063e08babb2',
    label: 'Amazon DE',
  },
  {
    value: '62d16215988f9063e08babb3',
    label: 'Amazon ES',
  },
  {
    value: '62d16215988f9063e08babb4',
    label: 'Amazon US',
  },
];
export const EBAY_MARKET_COUNTRY = [
  {
    value: '5e4c48f3196d3754c13faf5b',
    label: 'Ebay US',
  },
  {
    value: '5e4c48f3196d3754c13faf5d',
    label: 'Ebay DE',
  },
  {
    value: '5e4c48f3196d3754c13faf5c',
    label: 'Ebay FR',
  },
  {
    value: '5e4c48f3196d3754c13faf5e',
    label: 'Ebay ES',
  },
  {
    value: '5e4c48f3196d3754c13faf5a',
    label: 'Ebay IT',
  },
  {
    value: '5ea1baf6d3d4e8c6dfa1b9f9',
    label: 'Ebay GB',
  },
];

export const EXPORT_FORMATS = [
  { saveFormat: 'xlsx', name: 'XLSX', code: '.xlsx/v1' },
  { saveFormat: 'xlsx', name: 'XLSX2', code: '.xlsx/v2' },
  { saveFormat: 'csv', name: 'CSV', code: '.csv/v1' },
  { saveFormat: 'csv', name: 'CSV2', code: '.csv/v2' },
  { saveFormat: 'xml', name: 'XML', code: '.xml' },
  { saveFormat: 'json', name: 'JSON', code: '.json' },
];

export const AMAZON_MWS = {
  EU: '4528-2904-5237',
  NA: '2453-3247-6639',
};

export const LOGIN_ERRORS = {
  CREDENTIALS: 0,
  ROLE_EXPIRED: 1,
  FORCE_TO_RESET_PASSWORD: 2,
  NO_EMAIL_FROM_SOCIAL: 3,
};

export const PAYMENT_METHODS = {
  PAYPAL: 'paypal',
  CREDIT: 'credit',
};

export const ECREDIT_SOURCES = {
  return_order: 'return_order',
  payment: 'payment',
  credit: 'credit',
  withdraw: 'withdraw',
  cashback_products: 'cashback_products',
};

export const QUANTITIES = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
];

export const EXCLUDE_FROM_IMPORT = ['price', 'description', 'tags', 'sku'];
