export function refunds(state = { items: [] }, action) {
  switch (action.type) {
    case 'REFUNDS':
      return action.payload;

    default:
      return state;
  }
}

export function refundsIsLoading(state = true, action) {
  switch (action.type) {
    case 'REFUNDS_IS_LOADING':
      return action.payload;

    default:
      return state;
  }
}

export function refund(state = {}, action) {
  switch (action.type) {
    case 'REFUND':
      return action.payload;

    default:
      return state;
  }
}

export function refundIsLoading(state = true, action) {
  switch (action.type) {
    case 'REFUND_IS_LOADING':
      return action.payload;

    default:
      return state;
  }
}
