import request from 'tools/request';

function countries(payload) {
  return { type: 'COUNTRIES', payload };
}

export function getCountries(params) {
  return (dispatch) => {
    request('/api/country')
      .then((response) =>
        dispatch(
          countries(
            response.data
              .filter((country) => country?.countrycode !== 'ZZ')
              .sort((a, b) => {
                if (a?.country > b?.country) return 1;
                if (a?.country < b?.country) return -1;
                return 0;
              })
          )
        )
      )
      .catch((err) => console.log(err));
  };
}
