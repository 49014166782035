import { useSelector } from 'react-redux';
import regexifyString from 'regexify-string';
import { mergeDeep } from 'tools/utils';
import translations from './translations';
export const defaultTranslations = translations;

/**
 * @type {...defaultTranslations} TRANSLATIONS
 * @params {...defaultTranslations} TRANSLATIONS
 */
export const forcedPageMapping = {
  payment: {
    completed: 'payment',
    error: 'payment',
    authorize: 'payment',
  },
  payment_plan: {
    completed: 'payment_plan',
    error: 'payment_plan',
    authorize: 'payment_plan',
  },
  profile: {
    title: 'profile',
    tab: 'profile',
    plan: 'profile',
    user: 'profile',
    list: 'profile',
    password: 'profile',
    address: 'profile',
    invoice: 'profile',
    ecredit: 'profile',
  },
  channel: {
    settings: 'channel.edit',
  },
  auth: {
    howDidYouDiscoverBDroppy: 'auth',
    alreadySell: 'auth',
  },
};

export const mergeTranslation = (localeTranslation) =>
  mergeDeep(defaultTranslations(), localeTranslation);

function useTranslation() {
  const locale = useSelector((state) => state?.locale);
  const lang = locale.split('_')[0].toUpperCase();
  /**
   * @type {...defaultTranslations} TRANSLATIONS
   * @params {...defaultTranslations} TRANSLATIONS
   */
  const TRANSLATIONS = useSelector((state) => state.translations) || {};

  const t = (sentence, tokens) => {
    let translated = TRANSLATIONS?.[sentence] || sentence;
    if (tokens) {
      const tr = Object.keys(tokens).join('|');
      const regex = new RegExp('\\b(?:' + tr + ')\\b', 'gi');
      translated = translated.replace(regex, (matched) => tokens[matched]);
    }

    return translated;
  };

  const translationsParameters = (sentence, parameters = []) => {
    let index = 0;
    return regexifyString({
      pattern: /\[.*?\]/gm,
      decorator: () => parameters[index++],
      input: sentence,
    });
  };

  return {
    t,
    TRANSLATIONS,
    translationsParameters,
    locale,
    lang,
    mergeTranslation,
  };
}

export default useTranslation;
