import message from 'components/utils/message';
import { user } from 'reducers/user';
import request from 'tools/request';

import { attachUserCatalog } from './user';

function channelProducts(payload) {
  return { type: 'SET_CHANNEL_PRODUCTS', payload };
}

function channel(payload) {
  return { type: 'SET_CHANNEL', payload };
}

export function setChannelProducts(value = []) {
  return (dispatch) => {
    dispatch(channelProducts(value));
  };
}

export function setChannel(value = []) {
  return (dispatch) => {
    dispatch(channel(value));
  };
}

export function createCatalog({
  name,
  currency,
  language,
  ids,
  connections,
  allProducts = false,
  onSuccess = () => {},
  onComplete = () => {},
}) {
  return (dispatch) => {
    request(`/api/user_catalog`, {
      method: 'POST',
      data: {
        allProducts,
        autoConfirm: false,
        currency,
        ids,
        markup: 0,
        markupField: 'suggestedPrice',
        minQuantity: 0,
        markupRules: [],
        name,
        order: { singleRecipient: true },
        queryRule: [],
        rounding: 'no',
        connections,
        lang: language,
      },
    })
      .then((response) => {
        onSuccess(response.data);
        dispatch(attachUserCatalog());
      })
      .catch((err) => console.log('Error', err))
      .finally(onComplete);
  };
}

export function checkDomain({ domain_name, callback }) {
  return (dispatch) => {
    request(`/api/rclub/checkdomain`, {
      method: 'GET',
      params: {
        tlds: '',
        domain_name,
      },
    })
      .then((response) => {
        callback(response?.data);
      })
      .catch((err) => console.log('Error', err));
  };
}

export function registerTurnkey(data, callback) {
  return (dispatch) => {
    request('/api/turnkey/register', {
      method: 'POST',
      data,
    })
      .then((response) => {
        callback();
      })
      .catch((err) => {
        message.error('Turnkey creation could not be completed');
      });
  };
}
export function getConnections({ onSuccess = () => {}, onError = () => {} }) {
  return () => {
    request('/api/connection').then(onSuccess).catch(onError);
  };
}

export function createChannel({ data }) {
  return async () =>
    await request('/api/tars/channel', { method: 'POST', data });
}

export function getEbayLink() {
  return async () => await request('/api/tars/ebay/link/PRODUCTION');
}

export function createCatalogConnection({ data }) {
  return async () =>
    await request('/api/tars/catalog', { method: 'POST', data });
}

export function subscribeToTars() {
  return async (dispatch, getState) => {
    let response;
    let data = { user: {}, supplier: {} };
    try {
      await request('/api/tars/subscribe', { method: 'POST', data });
      response = await request('/api/user/me');
      dispatch(user({ ...getState()['user'], ...response?.data?.tars }));
    } catch (e) {
      return e;
    }
    return response;
  };
}

export function getChannelExport({ channelExportId }) {
  return async () =>
    await request('/api/tars/channelexport/' + channelExportId);
}

export function getAmazonLink({ channelExportId }) {
  return async () =>
    await request('/api/tars/amazon/generate-link/' + channelExportId);
}

export function updateChannelExport({ channelExportId, data }) {
  return async () =>
    await request('/api/tars/channelexport/' + channelExportId, {
      method: 'PUT',
      data,
    });
}

export function deleteChannel({ catalog }) {
  return async (dispatch) => {
    let response;
    try {
      response = await request('/api/user_catalog/' + catalog?._id + '/tars', {
        method: 'DELETE',
      });
      try {
        response = await dispatch(attachUserCatalog());
      } catch (e) {
        return e;
      }
    } catch (e) {
      console.log('deleteChannel error', e);
      return e;
    }
    return response;
  };
}

export function deleteRutterConnection({ catalogId }) {
  return async () => {
    await request(`/api/user_catalog/${catalogId}/rutter`, {
      method: 'DELETE',
    });
  };
}

export function deleteWixConnection({ catalogId }) {
  return async () => {
    await request(`/api/user_catalog/${catalogId}/wix`, {
      method: 'DELETE',
    });
  };
}

export function deleteSocialEcommerceConnection({ catalogId }) {
  return async () => {
    await request(`/api/user_catalog/${catalogId}/socialEcommerce`, {
      method: 'DELETE',
    });
  };
}

export function deleteShopifyBrandsSyncConnection({ catalogId }) {
  return async () => {
    await request(`/api/user_catalog/${catalogId}/shopifyBrandsSync`, {
      method: 'DELETE',
    });
  };
}

export function deleteShopifyConnection({ catalogId }) {
  return async () => {
    await request(`/api/user_catalog/${catalogId}/shopify`, {
      method: 'DELETE',
    });
  };
}

export function getEbayPolicies({ channelExportId }) {
  return async () => {
    let returnResponse, fulfillmentResponse, paymentResponse;
    returnResponse = request('/api/tars/ebay/policy/return/' + channelExportId);
    fulfillmentResponse = request(
      '/api/tars/ebay/policy/fulfillment/' + channelExportId
    );
    paymentResponse = request(
      '/api/tars/ebay/policy/payment/' + channelExportId
    );
    return await Promise.all([
      returnResponse,
      fulfillmentResponse,
      paymentResponse,
    ]);
  };
}

export function createRutterConnection(data) {
  return async () => {
    const body = {
      platform: data?.platform,
      component: data?.component,
      lang: data?.siteLanguage,
      imgOrientation: data?.imageOrientation,
      publicToken: data?.publicToken,
      url: null,
      code: data?.publicToken,
    };
    return await request(`/api/user_catalog/${data?.catalogId}/rutter`, {
      method: 'POST',
      data: body,
    });
  };
}

export function getTurnkey(data, callback) {
  return (dispatch) => {
    request('/api/turnkey/filter', {
      method: 'GET',
      params: data,
    })
      .then((response) => {
        callback(response.data.items[0]);
      })
      .catch((err) => console.log('Error', err));
  };
}

export function checkConnection1({ channelExportId }) {
  return async () =>
    await request(
      '/api/tars/channelexport/' + channelExportId + '/connection/check'
    );
}

export function checkConnection2({ channelExportId }) {
  return async () =>
    await request('/api/tars/cronjob_log/filter', {
      params: {
        q: {
          channelExport: channelExportId,
          operation: 'C-P-A',
          status: ['ERROR', 'DONE'],
        },
      },
    });
}

export function createWixConnection({ catalogId, publicToken }) {
  return async () => {
    await request(`/api/user_catalog/${catalogId}/wix`, {
      method: 'POST',
      data: {
        publicToken,
      },
    });
  };
}

export function createEkmConnection({ catalogId, publicToken }) {
  return async () => {
    const res = await request(`/api/user_catalog/${catalogId}/ekm`, {
      method: 'POST',
      data: {
        // publicToken,
      },
    });

    console.log('res', res);
  };
}
