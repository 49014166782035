export function setCookie(name, value = '', seconds = 0, path = '/') {
  const date = new Date();
  date.setTime(date.getTime() + seconds * 1000);
  const expires = date.toUTCString();
  document.cookie = `${name}=${value};expires=${expires};path=${path};samesite=none;secure=true`;
}

export function getCookie(name) {
  if (global?.document?.cookie?.length > 0) {
    let c_start = document.cookie.indexOf(name + '=');
    if (c_start != -1) {
      c_start = c_start + name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }

  return '';
}

export function removeCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
