import request from 'tools/request';

function settings(payload) {
  return { type: 'SET_SETTING', payload };
}

export function getSettings(params) {
  return (dispatch) => {
    request(
      '/api/setting/filter?q={%22type%22:{%22$nin%22:["words","turnKeyHtmls","FacebookPrivacyAndTerm","PrivacyAndTerm"]}}'
    )
      .then((response) => {
        dispatch(settings(response.data.items));
      })
      .catch((err) => console.log(err));
  };
}
