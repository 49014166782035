export const LOADER_TYPES = {
  RAISED: 'raised',
  TRANSPARENT: 'transparent',
};

const initialState = {
  isLoading: true,
  loaderType: LOADER_TYPES.RAISED,
  loaders: 1, // parte sempre in loading, viene decrementato dal getLocaleTranslations appena ha caricato le traduzioni
};

export function loader(state = initialState, action) {
  switch (action.type) {
    case 'IS_LOADING':
      let loaders = state?.loaders;
      if (action?.payload?.isLoading) {
        loaders = loaders + 1;
      } else {
        if (loaders < 1) {
          loaders = 0;
        } else {
          loaders = loaders - 1;
        }
      }
      return {
        isLoading: loaders >= 1,
        loaderType: action?.payload?.loaderType || initialState.loaderType,
        loaders,
      };
    default:
      return state;
  }
}
