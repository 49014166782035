export class ShopifyTokenProvider {
  constructor() {
    this.token = null;
  }

  static getInstance(token) {
    if (!this.instance) {
      this.instance = new ShopifyTokenProvider(token);
    }
    return this.instance;
  }

  setToken(token) {
    this.token = token;
  }
}
