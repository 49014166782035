import 'styles/general.css';
import 'styles/general.css';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';
import 'react-loading-skeleton/dist/skeleton.css';

import InitialData from 'components/generic/InitialData';
import { ThemeProvider } from 'next-themes';
import Head from 'next/head';
import NextNprogress from 'nextjs-progressbar';
import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { Provider as ReduxProvider } from 'react-redux';
import { BreakpointProvider } from 'react-socks';
import { persistor, store } from 'redux/store';
import { DARK_THEME_NAME, LIGHT_THEME_NAME } from 'tools/constants';
import { PersistGate } from 'redux-persist/integration/react';

import Loader from '../components/generic/loader';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Chat from '../components/utils/Chat';

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    if (
      process.env.env === 'PRODUCTION' &&
      window.location.origin === process.env.DASHBOARD_URL
    ) {
      hotjar.initialize(2013607, 6);
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="aCqehIRGLuiSXfes-IOo8M_o4I697jXCkqzFGLz-__I"
        />
        <link rel="shortcut icon" href="/favicon-bdroppy.png" />
        <title>BDroppy - Dashboard</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta
          property="og:image"
          key="og:image"
          content={`${process.env.DASHBOARD_URL}/BDroppy-logo-light.svg`}
        />
        <meta name="google" content="nositelinkssearchbox" key="sitelinks" />
        {/* eslint-disable-next-line @next/next/no-sync-scripts */}
        <script src="https://unpkg.com/share-api-polyfill/dist/share-min.js"></script>
      </Head>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {() => (
            <BreakpointProvider>
              <ThemeProvider themes={[LIGHT_THEME_NAME, DARK_THEME_NAME]}>
                <GoogleOAuthProvider clientId={process.env.GOOGLE_APP_ID}>
                  <NextNprogress
                    color="#29D"
                    startPosition={0.3}
                    stopDelayMs={200}
                    height={3}
                    showOnShallow={true}
                  />
                  <InitialData />
                  <Chat />
                  <Loader />
                  <Component {...pageProps} />
                </GoogleOAuthProvider>
              </ThemeProvider>
            </BreakpointProvider>
          )}
        </PersistGate>
      </ReduxProvider>
    </>
  );
}

export default MyApp;
