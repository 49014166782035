import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  getLocaleTranslation,
  setLangDescription,
  setLocale,
  setTranslation,
} from 'actions/translations';
import {
  setChannelcode,
  setIsConfirmed,
  setReferralCode,
  setSource,
} from 'actions/ui';
import { getUser } from 'actions/user';
import useTranslation, { mergeTranslation } from 'hooks/useTranslation';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { ADDRESS_TYPE } from '../../tools/constants';
import { getToken, setToken } from '../../tools/utils';
import { getLocalStorage, setLocalStorage } from '../../tools/localStorage';
import { ShopifyTokenProvider } from '../../tools/shopifyTokenProvider';
import { useIsEmbedded } from '../../hooks/useIsEmbedded';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function InitialData() {
  const dispatch = useDispatch();
  const { locale } = useTranslation();
  const user = useSelector((state) => state?.user);
  const status = user?.status;
  let timeoutRef = useRef(null);
  const addresses = useSelector((state) => state.addresses);
  const router = useRouterWithLang();
  const isEmbedded = useIsEmbedded();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const { lang } = router.query;
  const query = router.query;
  const channelcode = query?.channelCode || query.channelcode;
  const catalogs = useSelector((state) => state?.user?.userCatalogs);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const referralCode = query?.referralCode || query.referralcode;

  useEffect(() => {
    if (!isLoading) {
      let tempAddress = addresses.filter(
        (a) => a.type === ADDRESS_TYPE.BILLING
      )[0];
      if (
        process.env.env === 'PRODUCTION' &&
        window.location.origin === process.env.DASHBOARD_URL &&
        !isEmbedded
      ) {
        TagManager.initialize({
          gtmId: process.env.TAG_MANAGER_ID,
          dataLayer: {
            userEmail: user?.email,
            userCountry: user?.countryCode?.toLowerCase(),
            userCity: tempAddress?.city,
            userZipcode: tempAddress?.cap,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (router?.route === '/wix') {
      dispatch(setSource('wix'));
    }
  }, [dispatch, router]);

  useEffect(() => {
    if (channelcode) {
      dispatch(setChannelcode({ channelcode }));
    }
  }, [dispatch, channelcode]);

  useEffect(() => {
    if (referralCode) {
      dispatch(setReferralCode(referralCode));
      dispatch(setChannelcode({ channelcode: `affiliation_${referralCode}` }));
    }
  }, [dispatch, channelcode, referralCode]);

  useEffect(() => {
    if (isEmbedded) {
      setTimeout(
        () =>
          dispatch(
            getUser(
              false,
              () => {},
              () => {}
            )
          ),
        1000
      );
    } else {
      dispatch(
        getUser(
          false,
          () => {},
          () => {}
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const description = getLocalStorage('lang_description');
      if (description) {
        dispatch(setLangDescription(description));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (router.isReady) {
      if (lang) {
        dispatch(
          getLocaleTranslation(lang, (translations) => {
            dispatch(setTranslation(mergeTranslation(translations || {})));
            dispatch(setLocale(lang));
            setLocalStorage(`cachedLocale`, lang);
          })
        );
        setLocalStorage(`lang_description`, lang);
        dispatch(setLangDescription(lang));
      } else {
        let cachedLocale = getLocalStorage('cachedLocale');
        if (cachedLocale === null) {
          dispatch(setLocale('it_IT'));
        } else {
          dispatch(setLocale(cachedLocale));
        }
        dispatch(
          getLocaleTranslation(
            cachedLocale || 'it_IT',
            (translations) => {
              dispatch(setTranslation(mergeTranslation(translations || {})));
            },
            () => {}
          )
        );
        router.replace(
          {
            pathname: router?.pathname,
            query: {
              ...router?.query,
              lang: cachedLocale || 'it_IT',
            },
          },
          undefined,
          { shallow: true }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lang, router.isReady]);

  useEffect(() => {
    if (status === 5) {
      dispatch(setIsConfirmed(user.email));
    }
  }, [dispatch, status, user.email]);

  const closeIfLoggedIn = useCallback(
    async (event) => {
      if (!router.isReady) return;
      if (
        router.pathname.startsWith('/register') ||
        router.pathname.startsWith('/shopify-auth')
      )
        return;
      if (event.data.message !== 'close_if_logged_in') {
        return;
      }
      // if (event.origin !== process.env.DASHBOARD_URL) {
      //   return;
      // }

      let shopifyTokenProvider = ShopifyTokenProvider.getInstance();
      let shopifyToken = shopifyTokenProvider.token;
      let token = getToken() || shopifyToken;
      if (token) {
        window.parent.postMessage(
          { message: 'send_to_shopify_app', token },
          '*'
        );
      }
    },
    [router.isReady, router.pathname]
  );
  const loginFromEmbedded = useCallback((event) => {
    if (event.data.message !== 'login_if_logged_out') {
      return;
    }
    let shopifyTokenProvider = ShopifyTokenProvider.getInstance();
    let shopifyToken = shopifyTokenProvider.token;
    if (getToken() || shopifyToken) {
      return;
    }
    const { token } = event.data;
    shopifyTokenProvider.setToken(token);
    setToken(token);
  }, []);
  useEffect(() => {
    if (isEmbedded) {
      window.addEventListener('message', closeIfLoggedIn);
      window.addEventListener('message', loginFromEmbedded);
    }
    return () => {
      window.removeEventListener('message', closeIfLoggedIn);
      window.removeEventListener('message', loginFromEmbedded);
    };
  }, [closeIfLoggedIn, isEmbedded, loginFromEmbedded]);

  return null;
}
