import message from 'components/utils/message';
import request from 'tools/request';
import { getObjectFromDeepKeyValues } from 'tools/utils';
import { setIsLoading } from 'actions/loader';

export function setTranslation(payload) {
  return { type: 'SET_TRANSLATION', payload };
}

export function setLocale(payload) {
  return { type: 'SET_LOCALE', payload };
}

export function setLangDescription(payload) {
  return { type: 'SET_LANG_DESCRIPTION', payload };
}

export function setCountryShipping(payload) {
  return { type: 'SET_COUNTRY_SHIPPING', payload };
}

export function setCurrencies(payload) {
  return { type: 'SET_CURRENCY', payload };
}

export function getLocaleTranslation(
  locale,
  onComplete = () => {},
  onError = () => {}
) {
  return (dispatch) => {
    let translations = {};
    let idsTranslations = {};
    request(`/api/content/lang/${locale}`)
      .then((response) => {
        let contentsLang = response?.data;
        const contentsLangObj = {};
        const idsLangObj = {};
        contentsLang.forEach((contentLang) => {
          contentsLangObj[contentLang.code] = contentLang.content;
          idsLangObj[contentLang.code] = contentLang._id;
        });
        translations = getObjectFromDeepKeyValues(contentsLangObj);
        idsTranslations = getObjectFromDeepKeyValues(idsLangObj);
        dispatch(setIsLoading(false));
        onComplete(translations, idsTranslations);
      })
      .catch((err) => {
        onError();
      });
  };
}

export function createContentTranslation(
  code,
  lang,
  content,
  page,
  onComplete = () => {}
) {
  return (dispatch) => {
    request('/api/content', {
      method: 'POST',
      data: {
        type: 'label',
        code,
        lang,
        content,
        page,
      },
    })
      .then((response) => {})
      .catch((err) => message.error("Can't create translation"))
      .finally(() => onComplete());
  };
}

export function updateContentTranslation(
  _id,
  content,
  page,
  onComplete = () => {}
) {
  return (dispatch) => {
    request(`/api/content/${_id}`, {
      method: 'PUT',
      data: {
        content,
        page,
      },
    })
      .then((response) => {
        console.log('response update', response);
      })
      .catch((err) => message.error("Can't create translation"))
      .finally(() => onComplete());
  };
}

export function getPlatformPages(onComplete = () => {}) {
  return () => {
    let pages = [];
    request(`/api/Page`)
      .then((response) => {
        pages = response?.data || [];
      })
      .catch((err) => message.error("Can't load pages"))
      .finally(() => onComplete(pages));
  };
}

export function createPlatformPages(pageName, code, onSuccess = () => {}) {
  return () => {
    request(`/api/Page`, {
      method: 'POST',
      data: {
        code,
        name: pageName,
      },
    })
      .then((response) => onSuccess(response.data))
      .catch(() => message.error(`Can't create page ${pageName} ${code}`))
      .finally();
  };
}
