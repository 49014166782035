function isLoading(payload) {
  return { type: 'IS_LOADING', payload };
}

export function setIsLoading(params) {
  return (dispatch) => {
    dispatch(
      isLoading({ isLoading: params.isLoading, loaderType: params.loaderType })
    );
  };
}
