import { useRouter } from 'next/router';
import { parseQueryString } from '../tools/utils';

export const useRouterWithLang = () => {
  const router = useRouter();
  const routerWithLang = Object.assign({}, router);

  routerWithLang.push = async (url, as, options) => {
    const { query: urlQuery, pathname: urlPathname } = parseQueryString(url);
    if (as) {
      const { query: asQuery, pathname: asPathname } = parseQueryString(as);
      await router.push(
        {
          pathname: urlPathname,
          // combine router.query and query props
          query: {
            ...urlQuery,
            lang: router?.query?.lang,
          },
        },
        {
          pathname: asPathname,
          // combine router.query and query props
          query: {
            ...asQuery,
            lang: router?.query?.lang,
          },
        },
        options
      );
    } else {
      await router.push(
        {
          pathname: urlPathname,
          // combine router.query and query props
          query: {
            ...urlQuery,
            lang: router?.query?.lang,
          },
        },
        as,
        options
      );
    }
  };

  return { ...routerWithLang };
};
